import {ApolloError} from "@apollo/client";
import React, {ReactElement, useState} from "react";
import {Response} from "../../models/response";
import {Survey, SurveyStatus, SurveyAccessLevel} from "../../models/survey";
import {checkIfEmpty, currencySymbol, formatDate} from "../../utility/utility";
import {Icon} from "../icon";
import {ImageModal} from "../image-modal";
import {RichTextInput} from "../rich-text-input";
import {Spinner} from "../spinner";
import {StatusBlocked} from "../status-blocked";
import styles from "./intro-details.module.scss";
import {useAuthentication} from "../../hooks/user/useAuthentication";

export interface CloseAtTextProps {
	survey: Survey;
}

const CloseAtText = (
	{survey}: CloseAtTextProps,
): ReactElement => {
	const {status, closeAt, closeAfterResponsesCount, completedResponseCount = 0} = survey;
	if (status !== SurveyStatus.OPEN) return <span> Currently not available</span>;
	return (
		<div className={styles.closeContainer}>
			{closeAt && <span>
				<Icon size="extrasmall" name="calendar" /> Due by {formatDate(closeAt)}
			</span>}
			{closeAfterResponsesCount &&
				<span>
					<span className={styles.count}>
						{`${closeAfterResponsesCount - completedResponseCount}`}
					</span> Responses left</span>
			}
			{!closeAt && !closeAfterResponsesCount && <span>Available now</span>}
		</div>
	);
};

export interface IntroDetailsProps {
	survey?: Survey;
	loading?: boolean;
	error?: ApolloError;
	currentResponse?: Response;
	isPreview?: boolean;
}

const IntroDetails = (props: IntroDetailsProps): ReactElement => {
	const {user} = useAuthentication();
	const {survey, loading, error, isPreview, currentResponse} = props;

	const [showModal, setShowModal] = useState<boolean>(false);

	const handleShowModal = (): void => {
		setShowModal(prevState => !prevState);
	};

	const permissions = survey?.permissions;
	if (loading) {
		return <Spinner />;
	}

	if (currentResponse?.completedAt) {
		return <StatusBlocked blockedReason="ANSWERED" />;
	}

	if (survey?.deletedAt) {
		return <StatusBlocked blockedReason="CLOSED" />;
	}

	if (survey?.status === SurveyStatus.CLOSED && !isPreview) {
		return <StatusBlocked blockedReason="CLOSED" />;
	}

	if (survey?.status === SurveyStatus.DRAFT && !isPreview) {
		return <StatusBlocked blockedReason="UNPUBLISHED" />;
	}

	if (!user && error?.message.includes("read")) {
		return <StatusBlocked blockedReason="NOT_AUTHORIZED" />;
	}

	// If a survey is private, not a preview, and the user cannot create, show CLOSED
	if (survey?.accessLevel === SurveyAccessLevel.PRIVATE
 && !permissions?.includes("Response.create") && !isPreview) {
		return <StatusBlocked blockedReason="CLOSED" />;
	}

	if (error) {
		if (error.message.includes("read")) {
			return <StatusBlocked blockedReason="CLOSED"/>;
		}
		// An error for private surveys. Survey will be null and error will include authentication
		if (error.message.includes("Authentication")) {
			return <StatusBlocked blockedReason="NOT_AUTHORIZED" />;
		}
		return <StatusBlocked blockedReason="GENERIC" />;
	}

	if (!survey) {
		return <StatusBlocked blockedReason="GENERIC" />;
	}

	return (
		<div className={styles.contentContainer}>
			{
				(survey.introImage || survey.introVideo) && <>
					{survey.introImage &&
					<div className={styles.imageContainer}>
						<img
							src={survey.introImage.medium}
							className={styles.image}
							onClick={handleShowModal}
						/>
					</div>
					}
					{survey.introVideo &&
						<div className={styles.video} onClick={handleShowModal}>
							<img src={survey.introVideo.medium} />
							<div className={styles.play}>
								<div className={styles.arrow}/>
							</div>
						</div>
					}
					<ImageModal
						media={{
							image: survey.introImage,
							video: survey.introVideo,
						}}
						isShowing={showModal}
						handleClose={handleShowModal}
						muteAudio={false}
					/>
				</>
			}
			<h1 className={styles.title}>{survey?.title}</h1>
			<div className={styles.rich}>
				{checkIfEmpty(survey.introduction) &&
					<RichTextInput
						text={survey.introduction}
						flex={2}
					/>
				}
				<div className={styles.additional}>
					<div className={styles.stats}>
						{survey.incentiveAmount && survey.incentiveAmount !== "0"
						&& survey.incentiveAmount !== "0.00" &&
						<span>$$ Incentive: {currencySymbol(survey.incentiveCurrency)}
							{survey.incentiveAmount}</span>
						}
						{survey.estimatedCompletionTime ?
							<span>Estimated Time: {survey.estimatedCompletionTime} minutes</span> :
							<></>
						}
						<span>
							<Icon size="extrasmall" name="help"/> Questions: {survey.questionCount}
						</span>
						<CloseAtText survey={survey} />
					</div>
					{survey.instructions && survey.instructions.trim() !== "" &&
						checkIfEmpty(survey.instructions) &&
						<RichTextInput text={survey.instructions} />
					}
				</div>
			</div>
		</div>
	);
};

export {IntroDetails};
